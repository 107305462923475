.header-box {
    height: 160px;
    background-color: #001f46;
}

@media (max-width: 575.98px) { 
    .header-box {
        height: 120px;
    }
}
