@font-face {
  font-family: 'Helvetica';
  src: url(../src/assets/fonts/Helvetica.ttc);
}

@font-face {
  font-family: 'Helvetica-bold';
  src: url(../src/assets/fonts/Helvetica-Bold.ttf);
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Helvetica', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-body {
  height: calc(100% - 148px);
}
