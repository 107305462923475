.content-center {
    justify-content: center;
    align-items: center;
}

.hr-depth {
    height: 1px;
    background-color: darkgray;
    margin-bottom: 14px;
}

.card-group-content {
    margin: 0 auto;
    max-width: 790px;
}

.panel-content {
    padding: 3%;
    min-height: Calc(100vh - 290px);
}

.mobile-img {
    object-fit: cover;
    margin-bottom: -35%;
}

#root {
    background-image: url('../../assets/images/home-bg.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    min-height: 100%;
  }

  .home-card {
    border-radius: 20px !important;
    padding: 2rem;
    overflow: hidden;
  }

  .card-title {
    color: #001f46;
    font-family: 'Helvetica-bold';
    font-size: 2rem;
  }

  .card-text {
    color: #666666;
    font-size: 15px;
    padding-right: 30px;
    line-height: 1.4;
  }

  .app-store-icon {
      margin-bottom: 30px;
      align-items: center;
  }

  .app-store-badge {
      max-width: 45%;
  }

  .app-store-badge img { 
      display: inline-block;
      max-width: 100%;
  }

  .play-store-badge {
      max-width: 45%;
  }
    .play-store-badge img {
        display: inline-block;
        max-width: 100%;
        width: 133px;
    }

 @media (max-width: 575.98px) { 
    .card-title {
        padding-bottom: 5px;
        font-size: 1.25rem;
      }
    
      .card-text {
        font-size: 11px;
        padding-right: 30px;
        line-height: 1.2;
      }

 }