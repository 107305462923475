.footer-box {
    bottom: 20px;
}

.footer-box img {
    width: 150px;
}

.footer-info {
    color: white;
    font-size: 14px;
}

@media (max-width: 575.98px) { 
    .footer-info {
        font-size: 11px;
    }

    .footer-box img {
        width: 100px;
    }
}
