.center-panel {
    background-color: white;
    border-radius: 10px;
    padding: 25px 0;
    width: 65%;
    z-index: 10;
}

.input-label {
    text-align: right;
}

.reset-btn {
    background-color: #001f46 !important;
    font-size: 18px !important;
    width: 30%;
}

.alert-box {
    position: absolute !important;
    right: 10px;
    top: 10px;
    min-width: 350px;
}

@media (max-width: 575.98px) { 
    .center-panel {
        width: 100%;
    }

    .reset-btn {
        width: 80%;
    }

    .input-label {
        text-align: left;
    }

    .password-info {
        font-size: 16px !important;
    }

    .alert-box {
        left: 10px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) { 
    .center-panel {
        width: 80%;
    }

    .reset-btn {
        width: 80%;
    }

    .alert-box {
        left: 10px;
    }
 }

 .forgot-password-container {
     display: flex;
     justify-content: center;
     align-items: center;
     min-height: Calc(100vh - 290px);
 }